<template>
  <div class="v3-add-detention-adult-form">
    <LazyLoadSelect
      type="adults"
      :selected="state.form.adult"
      placeholder="Select one"
      @update:model-value="
        (value) => {
          state.form.adult = value
        }
      "
      :invalid-feedback="errors.adult || state.serverErrors.user_id"
    ></LazyLoadSelect>
    <div>
      <InfoBox
        v-if="state.serverRes"
        class="mt-4"
        :class="{ danger: Object.keys(state.serverErrors).length }"
        :title="state.serverRes.title"
        >{{ state.serverRes.message }}</InfoBox
      >
    </div>
    <div class="text-center">
      <LoadingButton class="me-2 px-4" @click="onCancel" rounded
        >Cancel</LoadingButton
      >
      <LoadingButton
        :is-loading="state.isProcessing"
        @click="submit()"
        class="mt-4 px-4"
        solid
        rounded
        >Submit</LoadingButton
      >
    </div>
  </div>
</template>

<script>
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import { reactive, computed } from "vue"
import { useStore } from "vuex"
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"

export default {
  components: { LazyLoadSelect, LoadingButton, InfoBox },
  emits: ["cancel"],
  setup(props, { emit }) {
    const state = reactive({
      form: {
        adult: null
      },
      isProcessing: false,
      serverErrors: {},
      serverRes: null
    })

    const store = useStore()

    const validations = {
      form: {
        adult: {
          required: helpers.withMessage("This field is required", required)
        }
      }
    }

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })
      return errorObj
    })

    const v$ = useVuelidate(validations.form, state.form)
    const isFormValid = computed(() => !v$.value.$invalid)
    const activeSchool = computed(() => store.getters["schools/activeSchool"])

    const submit = () => {
      const data = {
        user_id: state.form.adult ? state.form.adult.value.id : null,
        school_id: activeSchool.value.id
      }
      if (isFormValid.value) {
        state.isProcessing = true
        store
          .dispatch("detentionAdults/createDetentionAdult", data)
          .then(() => {
            setResponseInfoBox("Success", "Successfully added!")
            state.isProcessing = false
            state.serverErrors = {}
            setTimeout(() => {
              setResponseInfoBox()
              store.dispatch("detentionAdults/getDetentionAdults")
              emit("cancel")
            }, 1800)
          })
          .catch((err) => {
            const res = err.response.data
            state.serverErrors = res.errors ? res.errors : {}
            setResponseInfoBox("Error", res.message)
            state.isProcessing = false
          })
      } else {
        v$.value.$touch()
      }
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const onCancel = () => {
      emit("cancel")
    }

    return { state, submit, onCancel, errors }
  }
}
</script>

<style></style>
