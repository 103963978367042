<template>
  <div class="container">
    <div class="row justify-content-center mt-4">
      <div class="col-md-8">
        <div class="v3-data-table full-height-table py-5 px-4">
          <div class="my-3">
            <SelectList
              :data="state.form.rooms"
              select-label="Select rooms eligible for Auto Pass"
              select-placeholder="Select location"
              select-type="locations"
              :reduce="(item) => item.value.id"
              counter-icon="ri-map-pin-2-fill"
              counter-icon-class="simple"
              :close-after-select="false"
              :invalid-feedback="state.serverErrors"
              @update-list-data="(value) => updateData(value)"
              @selected-element="(value) => scrollToSpecificRoom(value)"
            />
          </div>
          <div>
            <InfoBox
              v-if="state.serverRes"
              class="my-4"
              :class="{ danger: Object.keys(state.serverErrors).length }"
              :title="state.serverRes.title"
              >{{ state.serverRes.message }}</InfoBox
            >
          </div>
          <div>
            <LoadingButton
              :is-loading="isProcessing"
              @click="submit()"
              class="px-4"
              solid
              rounded
              >Save settings</LoadingButton
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue"
import { useStore } from "vuex"
import SelectList from "@/v3components/shared/Form/SelectList"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"

export default {
  name: "AutoPassForm",
  components: { SelectList, InfoBox, LoadingButton },
  setup() {
    const store = useStore()
    const state = reactive({
      isProcessing: false,
      form: {
        rooms: []
      },
      selectedRoom: "",
      serverErrors: {},
      serverRes: null
    })

    onMounted(() => {
      store.dispatch("rooms/getAutoPassLimitV7").then((response) => {
        const data = response.data.data
        if (data.auto_pass_rooms && data.auto_pass_rooms.length) {
          setAutoPassLimitData(data)
        }
      })
    })

    const setAutoPassLimitData = (data) => {
      data.auto_pass_rooms.forEach((el) => {
        state.form.rooms.push({
          value: {
            id: el.room_id,
            type: "App\\Models\\Room"
          },
          label: el.room.name
        })
      })
    }

    const updateData = (value) => {
      state.form.rooms = value
      state.serverErrors = {}
      state.serverRes = null
    }

    const submit = () => {
      state.isProcessing = true
      store
        .dispatch("rooms/setAutoPassLimitV7", {
          autopass_rooms: state.form.rooms.map((el) => el)
        })
        .then(() => {
          setResponseInfoBox("Success", "Successfully saved!")
          state.isProcessing = false
          state.serverErrors = {}
          setTimeout(() => {
            setResponseInfoBox()
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const scrollToSpecificRoom = (roomId) => {
      setTimeout(() => {
        document.getElementById("row-" + roomId).scrollIntoView()
      }, 200)
    }

    return {
      state,
      setAutoPassLimitData,
      updateData,
      submit,
      scrollToSpecificRoom
    }
  }
}
</script>
